import Vue from "vue";
import httpClient from "../services/http-client";
import { getQueryStringValue, getUser } from "../common/util";
import Editor from "@tinymce/tinymce-vue";
import { BootstrapVueIcons } from "bootstrap-vue";
Vue.use(BootstrapVueIcons);

const agentSearchId = "agent-search";
const clearContents = function() {
    vue.siteEditForm.getStarted = "";
    vue.validSubmiEditForm = true;
};
let vue;
const getBase64FromUrl = async url => {
    try {
        const data = await fetch(url);
        const blob = await data.blob();
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                const base64data = reader.result;
                resolve(base64data);
            };
            reader.onerror = error => reject(error);
        });
    } catch (e) {
        throw "File or url does not exists";
    }
};
export const agentSearch = {
    el: `#${agentSearchId}`,
    components: {
        editor: Editor // <- Important part
    },
    data() {
        return {
            screenType: {
                CURRENT: "",
                AGENT_LIST: "Agent List",
                AGENT_SITE_LIST: "Agent Site List",
                REASSIGN_SITES: "Reassign Sites"
            },
            msgType: {
                SITES_REASSIGNED: "Sites Reassigned",
                SITES_TURNED_ON: "Sites Turned On",
                SITES_TURNED_OFF: "Sites Turned Off",
                SITES_DELETE: "Sites Delete"
            },
            userEmail: "",
            agentName: "",
            agentNameOld: "",
            agentData: {},
            enableReassignToAgentBtn: false,
            reassignToAgent: {},
            selectedAgent: {},
            reassignedSitesCount: 0,
            // Agent site listing code strat=======================
            accountLists: [],
            siteName: "",
            total_Record: "",
            resMsg: "",
            fname: "",
            siteStatusId: "",
            turnoff: false,
            turnon: false,
            showModal: false,
            site_selected: [],
            site_selectAll: false,

            // Agent site listing code end=========================
            sitesReassignedSuccessMsg: false,
            sitesTurnedOnSuccessMsg: false,
            sitesTurnedOffSuccessMsg: false,
            isLoaderActive: false,
            showSiteDelModal: false,
            siteDeleteId: "",
            sitesDeleteSuccessMsg: false,
            showSiteEditModal: false,
            siteEditForm: {
                getStartedOld: "",
                getStarted: "",
                introductionOld: "",
                introduction: "",
                siteImagePreview: false,
                siteImage: "",
                siteImageName: "",
                siteImageBase64: "",
                siteImagePath: "",
                siteImageExt: "",
                siteImageOld: "",
                siteImagePreviewErr: true,
                siteImageExtInvalid: false,
                siteImageSizeError: false,
                publishSiteId: "",
                currentItemId: ""
            },
            validSubmiEditForm: false,
            siteIntroTextLimit: "100",
            siteIntroImageSize: "500",
            siteIntroImageExtAllow: "png,jpg,jpeg",
            invalidIntoMaxLimit: false,
            introData: {},
            siteIntroSaved: false,
            siteImageEditMode: false,
            initEditor: {
                menubar: false,
                statusbar: true,
                plugins: ["link", "wordcount"],
                toolbar: "bold | link | myCustomToolbarButton",
                branding: false,
                setup: function(editor) {
                    const that = this;
                    editor.ui.registry.addButton("myCustomToolbarButton", {
                        text: "Clear",
                        onAction: function() {
                            editor.setContent("");
                            clearContents();
                        }
                    });
                }
            }
        };
    },
    props: {},
    validations: {},
    watch: {
        siteEditForm: {
            handler() {
                if (
                    this.siteEditForm.getStartedOld === this.siteEditForm.getStarted &&
                    this.siteEditForm.introductionOld === this.siteEditForm.introduction &&
                    this.siteEditForm.siteImageOld === this.siteEditForm.siteImage
                ) {
                    this.validSubmiEditForm = false;
                }
            },
            deep: true
        }
    },
    computed: {
        isDisabled: function() {
            return !this.site_selected.length;
        },
        "siteEditForm.introduction": {
            get() {
                return this.siteEditForm.introduction;
            },
            set(v) {
                this.$emit("input", v);
            }
        },
        "siteEditForm.getStarted": {
            get() {
                return this.siteEditForm.getStarted;
            },
            set(v) {
                this.$emit("input", v);
            }
        }
    },
    mounted: function() {
        this.onMounted();
        let SiteIntroTextLimitId = document.getElementById("SiteIntroTextLimitId");
        let SiteIntroImageSizeId = document.getElementById("SiteIntroImageSizeId");
        let SiteIntroImageExtensionId = document.getElementById("SiteIntroImageExtensionId");
        this.siteIntroTextLimit = SiteIntroTextLimitId.value;
        this.siteIntroImageSize = SiteIntroImageSizeId.value;
        this.siteIntroImageExtension = SiteIntroImageExtensionId.value;
    },
    methods: {
        onKeyPressHandler(evt) {
            if (this.siteEditForm.introduction && this.siteEditForm.introduction.length > this.siteIntroTextLimit) {
                if ((evt.keyCode >= 48 && evt.keyCode <= 90) || evt.keyCode == 32) {
                    this.invalidIntoMaxLimit = true;
                    this.validSubmiEditForm = false;
                    evt.preventDefault();
                    return;
                }
            }
            if (this.siteEditForm.introduction.length <= this.siteIntroTextLimit) {
                this.siteIntroSaved = false;
                this.invalidIntoMaxLimit = false;
                this.validSubmiEditForm = true;
                return true;
            }
        },
        handleGetStarted(e, editor) {
            if (this.invalidIntoMaxLimit) {
                this.validSubmiEditForm = false;
            } else if (this.siteEditForm.getStartedOld != this.siteEditForm.getStarted) {
                this.validSubmiEditForm = true;
            }
        },
        getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        },
        uploadSiteImage(event) {
            this.siteIntroSaved = false;
            let uploadedFile = this.$refs.fileSiteImage.files[0];
            this._processSiteImageUpload(uploadedFile);
        },
        _processSiteImageUpload(uploadedFile) {
            const allowedExtensionsArr = this.siteIntroImageExtension.split(",");
            const fileExtension = uploadedFile.name
                .split(".")
                .pop()
                .toLowerCase();
            this.siteEditForm.siteImagePreview = true;
            if (!allowedExtensionsArr.includes(fileExtension.toLowerCase())) {
                this.siteEditForm.siteImage = "";
                this.siteEditForm.siteImageExt = "";
                this.siteEditForm.siteImageOld = this.siteEditForm.siteImage;
                this.siteEditForm.siteImagePreviewErr = true;
                this.$refs.fileSiteImage.value = null;
                this.siteEditForm.siteImageExtInvalid = true;
                this.siteEditForm.siteImageSizeError = false;
                this.validSubmiEditForm = false;
                return false;
            } else if (Math.ceil(uploadedFile.size / 1024) > this.siteIntroImageSize) {
                this.siteEditForm.siteImagePreviewErr = true;
                this.siteEditForm.siteImageSizeError = true;
                this.siteEditForm.siteImageExtInvalid = false;
                this.$refs.fileSiteImage.value = null;
                this.validSubmiEditForm = false;
                return false;
            } else {
                this.siteEditForm.siteImageExt = fileExtension;
                this.siteEditForm.siteImagePreviewErr = false;
                this.siteEditForm.siteImageSizeError = false;
                this.siteEditForm.siteImageExtInvalid = false;
                this.siteEditForm.siteImageName = uploadedFile.name;
                this.getBase64(uploadedFile).then(data => {
                    this.siteEditForm.siteImage = data;
                    this.siteEditForm.siteImageBase64 = data.substring(data.indexOf(",") + 1);
                    this.$refs.fileSiteImage.value = null;
                    this.validSubmiEditForm = true;
                    this.siteImageEditMode = false;
                    return true;
                });
            }
        },
        uploadSiteImageBrowse(event) {
            let fileInputElement = this.$refs.fileSiteImage;
            fileInputElement.click();
        },
        openMicrositeEditPopup(id, url, event) {
            this.showSiteEditModal = true;
            this.validSubmiEditForm = false;
            this.getIntroData(id, url);
        },
        closeMicrositeEditPopup(id, event) {
            this.invalidIntoMaxLimit = false;
            this.siteIntroSaved = false;
            this.showSiteEditModal = false;
            this.siteEditForm.siteImagePreviewErr = false;
            this.siteEditForm.siteImageSizeError = false;
            this.siteEditForm.siteImageExtInvalid = false;
            this.siteEditForm.siteImage = "";
            this.siteEditForm.siteImageName = "";
            this.siteEditForm.siteImageBase64 = "";
            this.siteEditForm.siteImagePreview = false;
            this.siteEditForm.siteImageExt = "";
        },
        removeSiteImage() {
            this.invalidIntoMaxLimit = false;
            this.siteIntroSaved = false;
            this.siteEditForm.siteImagePreviewErr = false;
            this.siteEditForm.siteImageSizeError = false;
            this.siteEditForm.siteImageExtInvalid = false;
            this.siteEditForm.siteImage = "";
            this.siteEditForm.siteImageName = "";
            this.siteEditForm.siteImageBase64 = "";
            this.siteEditForm.siteImagePreview = false;
            this.siteEditForm.siteImageExt = "";
            this.validSubmiEditForm = true;
        },
        getIntroData: function(itemId, url) {
            //reset all params
            this.introData = {};
            this.siteEditForm = {
                getStartedOld: "",
                getStarted: "",
                introductionOld: "",
                introduction: "",
                siteImagePreview: false,
                siteImage: "",
                siteImageName: "",
                siteImageBase64: "",
                siteImagePath: "",
                siteImageExt: "",
                siteImageOld: "",
                siteImagePreviewErr: true,
                siteImageExtInvalid: false,
                siteImageSizeError: false,
                publishSiteId: "",
                currentItemId: ""
            };
            if (url) {
                const publishSiteId = url.split("/")[1];
                this.siteEditForm.publishSiteId = publishSiteId;
                this.siteEditForm.currentItemId = itemId;
            }
            this.invalidIntoMaxLimit = false;
            this.siteIntroSaved = false;
            this.isLoaderActive = true;
            if (itemId) {
                httpClient.get(`Admin/GetCustomIntro?itemId={${itemId}}`).then(async result => {
                    this.siteImageEditMode = true;
                    this.isLoaderActive = false;
                    this.introData = result.data;
                    this.siteEditForm.getStartedOld = this.introData.Verbiage;
                    this.siteEditForm.getStarted = this.introData.Verbiage;
                    this.siteEditForm.introductionOld = this.introData.CustomIntroText;
                    this.siteEditForm.introduction = this.introData.CustomIntroText
                        ? this.introData.CustomIntroText
                        : null;
                    if (this.introData.CustomIntroImgPath) {
                        this.siteEditForm.siteImagePreview = true;
                        this.siteEditForm.siteImagePreviewErr = false;
                        this.siteEditForm.siteImagePath = this.introData.CustomIntroImgPath
                            ? this.introData.CustomIntroImgPath + "?" + new Date().getTime()
                            : null;
                        /*
                        this.siteEditForm.siteImageName =
                            this.introData.CustomIntroImgName + this.introData.CustomIntroImgExt;
                        */
                        if (this.siteEditForm.siteImagePath) {
                            let base64Data = await getBase64FromUrl(this.siteEditForm.siteImagePath).then(data => data);
                            this.siteEditForm.siteImage = base64Data;
                            this.siteEditForm.siteImageOld = base64Data;
                            this.siteEditForm.siteImageBase64 = base64Data.substring(base64Data.indexOf(",") + 1);
                        }
                        setTimeout(() => {
                            this.isLoaderActive = false;
                        }, 500);
                    }
                });
            }
        },
        submitIntro(id, event) {
            if (this.invalidIntoMaxLimit) {
                return;
            }
            event.preventDefault();
            if (id) {
                const getStartedText =
                    this.siteEditForm.getStarted && this.siteEditForm.getStarted.trim().length > 0
                        ? this.siteEditForm.getStarted
                        : "";
                const introductionText =
                    this.siteEditForm.introduction && this.siteEditForm.introduction.trim().length > 0
                        ? this.siteEditForm.introduction
                        : "";
                let data = {
                    ItemId: "{" + id + "}",
                    Verbiage: getStartedText,
                    CustomIntroText: introductionText,
                    CustomIntroImg: this.siteEditForm.siteImageBase64,
                    CustomIntroImgExt: "." + this.siteEditForm.siteImageExt,
                    CustomIntroImgName: this.siteEditForm.publishSiteId
                };

                httpClient.post("Admin/SaveCustomIntro", data).then(result => {
                    if (!result.data.ItemId) {
                        return false;
                    }
                    this.siteIntroSaved = true;
                    this.validSubmiEditForm = false;
                });
            } else {
                return false;
            }
        },
        getUserData: function() {
            this.userEmail = getQueryStringValue("username");
            if (this.userEmail) {
                httpClient.get(`Account/Get?username=${this.userEmail}`).then(result => {
                    let resData = window.__aflac_sitecore_env.name === "local" ? result.data[0] : result.data;
                    if (resData) {
                        // considering this page as first page of application and setting loggedin user detail in session for testing purpose only.
                        // we have to remove this later on.
                        localStorage.setItem("loggedInUserDetail", JSON.stringify(resData));
                    }
                });
            }
        },
        onSearch: function() {
            this.agentData = {};
            this.enableReassignToAgentBtn = false;
            this.reassignToAgent = {};
            this.isLoaderActive = true;
            if (this.screenType.CURRENT == this.screenType.AGENT_LIST) {
                this.agentNameOld = this.agentName;
            }
            httpClient.get(`Admin/GetAgentList?searchtext=${this.agentName}`).then(result => {
                this.agentData = result.data;
                this.isLoaderActive = false;
                if (!Array.isArray(this.agentData.AgentSearchList)) {
                    this.agentData.AgentSearchList = [];
                } else {
                    this.applySorting();
                    this.onActive();
                }
            });
        },
        onActive: function() {
            var buttonCtrl = document.getElementById("searchbtn_agent");
            var white_icon = document.getElementById("whitesearch_icon");
            var black_icon = document.getElementById("blacksearch_icon");
            if (this.agentName.length > 0) {
                buttonCtrl.classList.add("in");
                black_icon.classList.remove("in");
                white_icon.classList.add("in");
            } else {
                buttonCtrl.classList.remove("in");
                black_icon.classList.add("in");
                white_icon.classList.remove("in");
            }
        },
        applySorting: function() {
            this.agentData.AgentSearchList.sort((a, b) => {
                if (this.screenType.CURRENT == this.screenType.AGENT_LIST) {
                    if (a.Termed == "true") {
                        return -1;
                    }
                    if (a.Termed == "false") {
                        return 1;
                    }
                }
                if (a.FirstName.toUpperCase() > b.FirstName.toUpperCase()) {
                    return 1;
                }
                if (a.FirstName.toUpperCase() < b.FirstName.toUpperCase()) {
                    return -1;
                }
                if (a.LastName.toUpperCase() > b.LastName.toUpperCase()) {
                    return 1;
                } else if (a.LastName.toUpperCase() < b.LastName.toUpperCase()) {
                    return -1;
                }
            });
        },
        clearAgentScreenData: function() {
            this.agentName = "";
            this.agentData = {};
            this.reassignToAgent = {};
            this.enableReassignToAgentBtn = false;
        },
        navigateTo: function(navigateTo, data) {
            if (navigateTo == this.screenType.AGENT_LIST) {
                this.clearAgentScreenData();
                this.agentName = this.agentNameOld;
                this.onSearch();
            }
            if (navigateTo == this.screenType.REASSIGN_SITES) {
                this.clearAgentScreenData();
            }
            if (navigateTo == this.screenType.AGENT_SITE_LIST) {
                if (this.screenType.CURRENT == this.screenType.AGENT_LIST) {
                    this.selectedAgent = data;
                    this.fetchSites();
                }

                // if (this.screenType.CURRENT == this.screenType.REASSIGN_SITES) {
                //     setTimeout(function () { this.fetchSites(); }.bind(this), 5000);
                // }else{

                // }

                this.site_selectAll = false;
            }
            this.screenType.CURRENT = navigateTo;
        },
        sortAgentSearchByColumnName(columnName) {
            for (let property in this.$refs) {
                if (property.indexOf("_Arrow") > -1 && property != columnName + "_Arrow") {
                    let propertyArrowIcon = this.$refs[property];
                    propertyArrowIcon.classList.remove("sorting-arrow-down");
                    propertyArrowIcon.classList.remove("sorting-arrow-up");
                }
            }
            let order = "assending";
            let arrowIcon = this.$refs[columnName + "_Arrow"];
            if (arrowIcon.classList.contains("sorting-arrow-down")) {
                arrowIcon.classList.remove("sorting-arrow-down");
                arrowIcon.classList.add("sorting-arrow-up");
                order = "desending";
            } else if (arrowIcon.classList.contains("sorting-arrow-up")) {
                arrowIcon.classList.remove("sorting-arrow-up");
                arrowIcon.classList.add("sorting-arrow-down");
            } else {
                arrowIcon.classList.add("sorting-arrow-down");
            }
            this.agentData.AgentSearchList.sort((a, b) => {
                let val1 = a[columnName].toUpperCase();
                let val2 = b[columnName].toUpperCase();
                if (order == "assending") {
                    if (columnName == "Termed") {
                        // for boolean column
                        if (val1 == "TRUE") {
                            return -1;
                        }
                        if (val1 == "FALSE") {
                            return 1;
                        }
                    } else {
                        if (val1 > val2) {
                            return 1;
                        }
                        if (val1 < val2) {
                            return -1;
                        }
                    }
                } else {
                    if (columnName == "Termed") {
                        // for boolean column
                        if (val1 == "TRUE") {
                            return 1;
                        }
                        if (val1 == "FALSE") {
                            return -1;
                        }
                    } else {
                        if (val1 > val2) {
                            return -1;
                        }
                        if (val1 < val2) {
                            return 1;
                        }
                    }
                }
            });
        },
        onReassignToAgent: function() {
            let micrositeIdList = [];
            if (Array.isArray(this.site_selected)) {
                this.site_selected.forEach(siteId => {
                    micrositeIdList.push({ Id: siteId });
                });
            }
            let data = {
                UserName: this.reassignToAgent.EmailAddress,
                ListMicrositeId: micrositeIdList
            };
            httpClient.post("Admin/ReassignSites", data).then(result => {
                this.reassignedSitesCount = micrositeIdList.length;
                this.sitesReassignedSuccessMsg = true;
                this.accountLists = [];
                setTimeout(
                    function() {
                        this.fetchSites();
                    }.bind(this),
                    100
                );
                this.navigateTo(this.screenType.AGENT_SITE_LIST);
                this.showSuccessMsg(this.msgType.SITES_REASSIGNED);
            });
        },
        // Agent site listing code Start=========================
        onMsgClose: function() {
            this.sitesReassignedSuccessMsg = false;
            this.sitesTurnedOnSuccessMsg = false;
            this.sitesTurnedOffSuccessMsg = false;
            this.sitesDeleteSuccessMsg = false;
        },
        showSuccessMsg: function(type) {
            this.sitesReassignedSuccessMsg = false;
            this.sitesTurnedOnSuccessMsg = false;
            this.sitesTurnedOffSuccessMsg = false;
            if (type == this.msgType.SITES_REASSIGNED) {
                this.sitesReassignedSuccessMsg = true;
            } else if (type == this.msgType.SITES_TURNED_ON) {
                this.sitesTurnedOnSuccessMsg = true;
            } else if (type == this.msgType.SITES_TURNED_OFF) {
                this.sitesTurnedOffSuccessMsg = true;
            } else if (type == this.msgType.SITES_DELETE) {
                this.sitesDeleteSuccessMsg = true;
            }
        },
        fetchSites: function() {
            this.isLoaderActive = true;
            this.site_selected = [];
            this.accountLists = [];
            var obj = {
                UserName: this.selectedAgent.EmailAddress,
                SortOrder: "asc",
                SortColumn: "account"
            };
            httpClient.post("Microsite/GetMicrositeList", obj).then(result => {
                //httpClient.get("Microsite/GetMicrositeList").then(result => {
                this.isLoaderActive = false;
                this.total_Record = result.data.TotalRecord;
                this.resMsg = result.data.TotalRecord;
                this.accountLists = result.data.MicroSiteList;
                for (let i = 0; i < this.accountLists.length; i++) {
                    this.accountLists[i].MicrositeId = this.accountLists[i].MicrositeId.replace("{", "").replace(
                        "}",
                        ""
                    );
                }
            });
        },
        siteSelectAll() {
            this.site_selected = [];
            if (this.site_selectAll) {
                for (let i in this.accountLists) {
                    this.site_selected.push(this.accountLists[i].MicrositeId);
                }
            }
        },
        isChecked(event) {
            if (this.site_selected.length == 0 || this.site_selected.length < this.accountLists.length) {
                this.site_selectAll = false;
            }
            if (this.site_selected.length == this.accountLists.length) {
                this.site_selectAll = true;
            }
        },
        checkStatus(name, id, event) {
            if (event == false) {
                this.siteName = name;
                this.siteStatusId = id;
                this.showModal = true;
            } else {
                this.siteName = name;
                this.siteStatusId = id;
                let data = {
                    ItemId: this.siteStatusId,
                    Status: true
                };
                httpClient.post("Publish/UpdateStatus", data).then(result => {
                    this.showSuccessMsg(this.msgType.SITES_TURNED_ON);
                });

                return;
            }
        },
        hideModal(id) {
            this.showModal = false;
            this.accountLists.forEach(item => {
                if (item.MicrositeId == id) item.Active = true;
            });
        },
        disableSite() {
            this.showModal = false;
            let data = {
                ItemId: this.siteStatusId,
                Status: false
            };
            httpClient.post("Publish/UpdateStatus", data).then(result => {
                this.turnon = false;
                this.showSuccessMsg(this.msgType.SITES_TURNED_OFF);
                this.turnoff = true;
            });
        },
        cancel() {
            this.showModal = false;
        },
        confirmDelete(name, id, event) {
            this.sitesDeleteSuccessMsg = false;
            if (event) {
                this.siteName = name;
                this.siteDeleteId = id;
                this.showSiteDelModal = true;
            }
        },
        deleteSite(id) {
            this.showSiteDelModal = false;
            let data = {
                ItemId: "{" + id + "}"
            };
            httpClient.post("Delete/DeleteStatus", data).then(result => {
                if (!result.data.ItemId) {
                    return false;
                }
                this.showSuccessMsg(this.msgType.SITES_DELETE);
                this.accountLists = this.accountLists.filter(item => item.MicrositeId !== id);
            });
        },
        cancelDelete() {
            this.showSiteDelModal = false;
            return false;
        },
        sortSiteName() {
            let downIconId = document.getElementById("darrow1");
            let upIconId = document.getElementById("uarrow1");
            if (upIconId.style.display === "inline-block") {
                document.getElementById("darrow2").style.display = "none";
                document.getElementById("darrow3").style.display = "none";

                document.getElementById("uarrow2").style.display = "none";
                document.getElementById("uarrow3").style.display = "none";

                upIconId.style.display = "none";
                downIconId.style.display = "inline-block";
                this.accountLists.sort((a, b) => a.MicrositeName.localeCompare(b.MicrositeName));
            } else {
                document.getElementById("darrow2").style.display = "none";
                document.getElementById("darrow3").style.display = "none";

                document.getElementById("uarrow2").style.display = "none";
                document.getElementById("uarrow3").style.display = "none";

                upIconId.style.display = "inline-block";
                downIconId.style.display = "none";
                this.accountLists.sort((a, b) => b.MicrositeName.localeCompare(a.MicrositeName));
            }
        },
        sortSiteCreatedDate() {
            let downIconId = document.getElementById("darrow2");
            let upIconId = document.getElementById("uarrow2");
            if (upIconId.style.display === "inline-block") {
                document.getElementById("darrow1").style.display = "none";
                document.getElementById("darrow3").style.display = "none";

                document.getElementById("uarrow1").style.display = "none";
                document.getElementById("uarrow3").style.display = "none";

                upIconId.style.display = "none";
                downIconId.style.display = "inline-block";
                this.accountLists.sort((a, b) => {
                    var c = new Date(a.CreatedDate);
                    var d = new Date(b.CreatedDate);
                    return c - d;
                });
            } else {
                document.getElementById("darrow1").style.display = "none";
                document.getElementById("darrow3").style.display = "none";

                document.getElementById("uarrow1").style.display = "none";
                document.getElementById("uarrow3").style.display = "none";

                upIconId.style.display = "inline-block";
                downIconId.style.display = "none";
                this.accountLists.sort((a, b) => {
                    var c = new Date(b.CreatedDate);
                    var d = new Date(a.CreatedDate);
                    return c - d;
                });
            }
        },
        sortSiteLiveDate() {
            let downIconId = document.getElementById("darrow3");
            let upIconId = document.getElementById("uarrow3");
            if (upIconId.style.display === "inline-block") {
                document.getElementById("darrow2").style.display = "none";
                document.getElementById("darrow1").style.display = "none";

                document.getElementById("uarrow2").style.display = "none";
                document.getElementById("uarrow1").style.display = "none";

                upIconId.style.display = "none";
                downIconId.style.display = "inline-block";
                this.accountLists.sort((a, b) => {
                    var c = new Date(a.LiveDate);
                    var d = new Date(b.LiveDate);
                    return c - d;
                });
            } else {
                document.getElementById("darrow2").style.display = "none";
                document.getElementById("darrow1").style.display = "none";

                document.getElementById("uarrow2").style.display = "none";
                document.getElementById("uarrow1").style.display = "none";

                upIconId.style.display = "inline-block";
                downIconId.style.display = "none";
                this.accountLists.sort((a, b) => {
                    var c = new Date(b.LiveDate);
                    var d = new Date(a.LiveDate);
                    return c - d;
                });
            }
        },
        async onMounted() {
            this.screenType.CURRENT = this.screenType.AGENT_LIST;
            // Agent site listing code strat=======================
            // this.getUserData();
            // this.loggedInUserDetail = JSON.parse(localStorage.getItem("loggedInUserDetail"));
            this.loggedInUserDetail = await getUser();
            // Agent site listing code end=========================
        }
        // Agent site listing code end=========================
    }
};

if (document.getElementById(agentSearchId)) {
    vue = new Vue(agentSearch);
}
